<template>
	<el-card class="box-card">
		<el-row>
			<el-col :span="24">
				<Header />
				<div class="table-detali">
					<el-tabs v-model="activeName" @tab-click="handleClick">
						<el-tab-pane :label="translateTitle('KYC信息')" name="zero"></el-tab-pane>
						<el-tab-pane :label="translateTitle('个人信息')" name="first"></el-tab-pane>
						<el-tab-pane :label="translateTitle('通讯录')" name="third"></el-tab-pane>
						<!-- <el-tab-pane label="信用" name="third"></el-tab-pane> -->
					</el-tabs>
			
				</div>
				<div class="">
					<el-table class="custom-table" stripe v-loading="tableLoading" v-if="activeName == 'zero'"
						:data="kycData" style="width: 100%">
						<el-table-column prop="email" :label="translateTitle('邮箱')" show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="documentType" :label="translateTitle('类型')" show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="kycStatus" :label="translateTitle('状态')" show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="active" :label="translateTitle('操作')" width="300" show-overflow-tooltip>
							<template slot-scope="scope">
								<el-button class="primary-plain" type="primary" plain size="small"
									@click="initiateKYC(scope.row)">{{translateTitle("启动KYC")}}</el-button>
							</template>
						</el-table-column>
						<el-table-column prop="complianceStatus" :label="translateTitle('合规状态')" show-overflow-tooltip>
						</el-table-column>
					</el-table>
					<el-form v-if="activeName == 'first'" :model="ruleForm" :rules="rules" label-position="top"
						ref="ruleForm" label-width="100px" class="demo-ruleForm person-form">
						<div class="title mb15">{{translateTitle('法定姓名')}}</div>
						<el-row class="edress-content change-form" :gutter="50">
							<el-col :span="8">
								<el-form-item :label="translateTitle('名字')" prop="lastName">
									<el-input v-model="ruleForm.lastName" clearable
										:placeholder="translateTitle('请输入姓名')"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item :label="translateTitle('中间名字')" prop="middleName">
									<el-input v-model="ruleForm.middleName" clearable
										:placeholder="translateTitle('输入中间姓名')"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item :label="translateTitle('用户姓氏')" prop="firstName">
									<el-input v-model="ruleForm.firstName" clearable
										:placeholder="translateTitle('输入用户姓氏')"></el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<div class="title">{{translateTitle('联系方式')}}</div>
						<el-row class="edress-content change-form" :gutter="50">
							<el-col :span="8">
								<el-form-item :label="translateTitle('移动国家代码')" prop="phoneCountryCode">
									<el-select class="svg-container" v-model="ruleForm.phoneCountryCode" filterable
										clearable :placeholder="translateTitle('输入国籍')" @change="changePhoneCountry">
										<template #prefix>
											<span class="flex">
												<img v-if="phoneCountryLogo" :src="phoneCountryLogo" width="24"
													height="24" />
											</span>
										</template>
										<el-option v-for="item in options" :key="item.phoneCode" :label="item.name"
											:value="item.phoneCode">
											<div class="disal">
												<img :src="item.logo" width="24" height="24" />
												<span class="select-item">{{ item.name }}</span>
											</div>
										</el-option>
									</el-select>
								</el-form-item>
							</el-col>

							<el-col :span="8">
								<el-form-item :label="translateTitle('电子邮件')" prop="email">
									<el-input v-model="ruleForm.email" clearable
										:placeholder="translateTitle('输入电子邮件')"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item :label="translateTitle('电话号码')" prop="phone">
									<el-input v-model="ruleForm.phone" clearable
										:placeholder="translateTitle('输入电话号码')"></el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<div class="title">{{translateTitle('个人')}}</div>
						<el-row class="edress-content change-form" :gutter="50">
							<el-col :span="12">
								<el-form-item :label="translateTitle('标题')" prop="title">
									<el-select v-model="ruleForm.title" :placeholder="translateTitle('请选择')">
										<el-option v-for="item in options3" :key="item" :label="item" :value="item">
										</el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item :label="translateTitle('性别')" prop="gender">
									<el-select v-model="ruleForm.gender" clearable :placeholder="translateTitle('请选择')">
										<el-option v-for="item in options2" :key="item" :label="item" :value="item">
										</el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item :label="translateTitle('国籍')" prop="nationality">
									<el-select class="svg-container" v-model="ruleForm.nationality" filterable clearable
										:placeholder="translateTitle('输入国籍')" @change="changenationality">
										<template #prefix>
											<span class="flex">
												<img v-if="nationalityLogo" :src="nationalityLogo" width="24"
													height="24" />
											</span>
										</template>
										<el-option v-for="item in options" :key="item.alpha2" :label="item.name"
											:value="item.alpha2">
											<div class="disal">
												<img :src="item.logo" width="24" height="24" />
												<span class="select-item">{{ item.name }}</span>
											</div>
										</el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item :label="translateTitle('出生日期')" prop="dateOfBirth">
									<el-date-picker v-model="ruleForm.dateOfBirth" clearable format="yyyy-MM-dd"
										:placeholder="translateTitle('选择日期')" type="date" value-format="yyyy-MM-dd" />
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item :label="translateTitle('证件类型')" prop="gender">
									<el-select v-model="ruleForm.idType" clearable :placeholder="translateTitle('请选择')">
											<el-option v-for="item in idType" :key="item" :label="item" :value="item">
											</el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item :label="translateTitle('证件号码')" prop="idNumber">
									<el-input v-model="ruleForm.idNumber" clearable
										:placeholder="translateTitle('输入证件号码')"></el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<div class="title mb15">{{ translateTitle("居住地址") }}</div>
						<el-row class="edress-content change-form" :gutter="50">
							<el-col :span="12">
								<el-form-item :label="translateTitle('国家')" prop="deliveryCountry">
									<el-select class="svg-container" v-model="ruleForm.deliveryCountry"
										:placeholder="translateTitle('请选择国家')" @change="changedeliveryCountry"
										clearable>
										<template #prefix>
											<span class="flex">
												<img :src="deliveryCountryLogo" width="24" height="24" />
											</span>
										</template>
										<el-option v-for="item in options" :key="item.alpha2" :label="item.name"
											:value="item.alpha2">
											<div class="disal">
												<img :src="item.logo" width="24" height="24" />
												<span class="select-item">{{ item.name }}</span>
											</div>
										</el-option>
									</el-select>
								</el-form-item>
							</el-col>

							<el-col :span="12">
								<el-form-item :label="translateTitle('邮政编码')" prop="deliveryZipCode">
									<el-input v-model="ruleForm.deliveryZipCode" clearable
										:placeholder="translateTitle('请输入邮政编码')"></el-input>
								</el-form-item>
							</el-col>

							<el-col :span="12">
								<el-form-item :label="translateTitle('城市')" prop='deliveryCity'>
									<el-input v-model="ruleForm.deliveryCity" clearable
										:placeholder="translateTitle('请输入姓名')"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item class="" :label="translateTitle('省')" prop="deliveryState">
									<el-input v-model="ruleForm.deliveryState" clearable
										:placeholder="translateTitle('请输入省')"></el-input>
								</el-form-item>
							</el-col>

							<el-col :span="12">
								<el-form-item class="" :label="translateTitle('地址1')" prop="deliveryAddress">
									<el-input v-model="ruleForm.deliveryAddress" clearable
										:placeholder="translateTitle('请输入地址1')"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item class="" :label="translateTitle('地址2')" prop="deliveryAddressSecond">
									<el-input v-model="ruleForm.deliveryAddressSecond" clearable
										:placeholder="translateTitle('请输入地址2')"></el-input>
								</el-form-item>
							</el-col>
<!-- 
							<el-col :span="24" class="tr">
								<el-button class="sure-btn" :loading="loading1" @click="submitForm('ruleForm')">{{
									translateTitle("保存更改")
									}}</el-button>
							</el-col> -->
						</el-row>

						<div class="title mb15">{{ translateTitle("账单地址") }}</div>
						<el-row class="edress-content change-form" :gutter="50">

						<el-col :span="12">
							<el-form-item :label="translateTitle('国家')" prop="billingCountry">
								<el-select class="svg-container" v-model="ruleForm.billingCountry"
									:placeholder="translateTitle('请选择国家')" @change="changebillingCountry"
									clearable>
									<template #prefix>
										<span class="flex">
											<img :src="billingCountryLogo" width="24" height="24" />
										</span>
									</template>
									<el-option v-for="item in options" :key="item.alpha2" :label="item.name"
										:value="item.alpha2">
										<div class="disal">
											<img :src="item.logo" width="24" height="24" />
											<span class="select-item">{{ item.name }}</span>
										</div>
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>

						<el-col :span="12">
							<el-form-item :label="translateTitle('邮政编码')" prop="billingZipCode">
								<el-input v-model="ruleForm.billingZipCode" clearable
									:placeholder="translateTitle('请输入邮政编码')"></el-input>
							</el-form-item>
						</el-col>

						<el-col :span="12">
							<el-form-item :label="translateTitle('城市')" prop="billingCity">
								<el-input v-model="ruleForm.billingCity" clearable
									:placeholder="translateTitle('请输入城市')"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item class="" :label="translateTitle('省')" prop="billingState">
								<el-input v-model="ruleForm.billingState" clearable
									:placeholder="translateTitle('请输入省')"></el-input>
							</el-form-item>
						</el-col>


						<el-col :span="12">
							<el-form-item class="" :label="translateTitle('地址1')" prop="billingAddress">
								<el-input v-model="ruleForm.billingAddress" clearable
									:placeholder="translateTitle('请输入地址1')"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item class="" :label="translateTitle('地址2')" prop="billingAddressSecond">
								<el-input v-model="ruleForm.billingAddressSecond" clearable
									:placeholder="translateTitle('请输入地址2')"></el-input>
							</el-form-item>
						</el-col>
						
						<el-col :span="24" class="two-between" >
							<div class="title1" @click="copyBilling">{{ translateTitle("账单地址与居住地址一样") }}，<span
									class="common-color">{{translateTitle('一键同步')}}</span></div>
							<el-button class="sure-btn" :loading="loading1" @click="submitForm('ruleForm')">{{
							translateTitle("保存更改")
							}}</el-button>
						</el-col>
					
					</el-row>
						
					</el-form>
				</div>
				<el-table class="custom-table" stripe v-loading="tableLoading" v-if="activeName == 'third'"
					:data="tableData" style="width: 100%">
					<el-table-column prop="name" :label="translateTitle('姓名')" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="email" :label="translateTitle('邮箱')" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="address" :label="translateTitle('操作')" width="300" show-overflow-tooltip>
						<template slot-scope="scope">
							<el-button class="primary-plain" type="primary" plain size="small"
								@click="bankTransfer(scope.row)">{{translateTitle("转账")}}</el-button>
							<el-button class="danger-plain" type="danger" @click="deleteContact(scope.row)" size="small"
								plain>{{translateTitle('移除')}}</el-button>
						</template>
					</el-table-column>
				</el-table>
				<Pagination v-if="activeName == 'third'" :page.sync="contactQuery.page"
					:limit.sync="contactQuery.pageSize" :total="contactQuery.total" @pagination="getContact" />
				<div v-if="activeName == 'third'" class="two-between mt15">
					<el-button class="primary-plain" type="primary" size="small" plain @click="addPerson()">{{translateTitle("添加通讯录")}}</el-button>
					<!-- 分页 -->
					<Pagination v-if="type == '3'" :page.sync="contactQuery.page" :limit.sync="contactQuery.pageSize"
						:total="contactQuery.total" @pagination="getContact" />
				</div>

			</el-col>
		</el-row>
		<!-- 添加联系人 -->
		<DialogPop width="600px" :title="translateTitle('添加通讯录')" :visible="visible" :closeBtn="false"
			:btnloading="btnloading" :sureText="translateTitle('确定')" @onClose="onClose" @onSure="onSure('ruleForm1')">
			<div class="change-form">
				<el-form label-position="top" :model="ruleForm1" status-icon :rules="rules" ref="ruleForm1"
					label-width="100px" class="demo-ruleForm" hide-required-asterisk>
					<el-form-item :label="translateTitle('联系人姓名')" prop="name">
						<el-input v-model="ruleForm1.name" clearable :placeholder="translateTitle('请输入姓名')"></el-input>
					</el-form-item>
					<el-form-item :label="translateTitle('邮箱')" prop="email">
						<el-input v-model="ruleForm1.email" clearable
							:placeholder="translateTitle('请输入账户邮箱')"></el-input>
					</el-form-item>
				</el-form>
			</div>
		</DialogPop>
		<!-- 转账 -->
		<DialogPop width="700px" :title="translateTitle('转账方式')" :visible="visible1" :closeBtn="false"
			:btnloading="btnloading" :closeText="translateTitle('取消')" :sureText="translateTitle('转账')"
			@onClose="onClose1" @onSure="onSure1('ruleForm2')">
			<div class="change-form">
				<el-form label-position="top" :model="ruleForm2" :rules="rules1" ref="ruleForm2" label-width="100px"
					class="demo-ruleForm" hide-required-asterisk>
					<el-form-item :label="translateTitle('邮箱')" prop="email">
						<el-input size="large" :placeholder="translateTitle('输入收件人邮箱')" v-model="ruleForm2.email"
							clearable>
						</el-input>
					</el-form-item>
					<el-form-item :label="translateTitle('数量')" prop="amount">
						<el-input :placeholder="translateTitle('请输入数量')" size="large" v-model="ruleForm2.amount"
							@input="changeAmount" class="input-with-select" clearable @clear="changeAmount">
							<template slot="append"><img width="24"
									src="../../assets/imgs/icon_usdt@2x.png">USDT</template>
						</el-input>
					</el-form-item>
					<el-form-item label="" prop="">
						<div class="line2">
							<span class="left">{{ translateTitle("可用余额") }}</span>
							<span class="right"> &nbsp;{{ BalanceCoinNam }}&nbsp;USDT</span>
						</div>
						<div v-if="ruleForm2.amount" class="line3">
							<span class="left">{{ translateTitle("手续费") }}</span>
							<span class="right"> &nbsp;{{ transferAmount || 0 }} &nbsp;USDT</span>
						</div>
						<div v-if="ruleForm2.amount" class="line3">
							<span class="left">{{ translateTitle("实际到账") }}</span>
							<span class="right"> &nbsp;{{ subtr(ruleForm2.amount, transferAmount) || 0 }}
								&nbsp;USDT</span>
						</div>
					</el-form-item>
				</el-form>
			</div>
		</DialogPop>
	</el-card>
</template>

<script>
	import Headers from "../../components/accountHeader/accounIndex";
	import topHeader from "../../components/topHeader/index"
	import local from "@/utils/local";
	import {
		coinNames,
		getBalanceCoinNam,
		userBankBalance,
		coinLink,
		coinBalanceUSD,
		getTransferRate,
		calculateCharge
	} from "@/api/index";
	import {
		addContact,
		contactPage,
		deleteDontact,
		getNational,
		getUserinfo,
		coin_transfer,
		bank_transfer,
		createMccard,
		cryptoStatement,
		addUserInfo,
		getIdTypes,
	} from "@/api/accountManage";
	import {
		translateTitle
	} from "@/utils/i18n";
	import {
		multiply,
		subtr
	} from '@/utils/date'
	import {
		kycStatus,
		userKycDetails
	} from "@/api/kyc";
	export default {
		components: {
			Headers,
			topHeader
		},
		data() {
			const validateName = (rule, value, callback) => {
				var xreg = /^[a-zA-Z\s]*\S$/
				if (!xreg.test(value)) {
					callback(new Error(this.translateTitle("请输入正确格式")))
				} else {
					callback()
				}
			}
			
			const validatePhone = (rule, value, callback) => {
				// value.replace(/[^0-9]/g,'')"
				var xreg = /^[0-9]*$/
				if (!xreg.test(value)) {
					callback(new Error(this.translateTitle("请输入正确格式")))
				} else {
					callback()
				}
			}
			const validateAddress = (rule, value, callback) => {
				var xreg = /^[a-zA-Z0-9{\s}@,._-]*\S$/
				if (!xreg.test(value)) {
					callback(new Error(this.translateTitle("请输入正确格式")))
				} else {
					callback()
				}
			}
			const validateCode = (rule, value, callback) => {
				var xreg = /^[A-Za-z0-9]*$/
				if (!xreg.test(value)) {
					callback(new Error(this.translateTitle("请输入正确格式")))
				} else {
					callback()
				}
			}
			const validateEmail = (rule, value, callback) => {
				var xreg = /^[A-Za-z0-9_+@.-]*$/
				if (!xreg.test(value)) {
					callback(new Error(this.translateTitle("请输入正确格式")))
				} else {
					callback()
				}
			}
			const validateIdNumber = (rule, value, callback) => {
				var xreg = /^[A-Za-z0-9]*$/
				if (!xreg.test(value)) {
					callback(new Error(this.translateTitle("请输入正确格式")))
				} else {
					callback()
				}
			}
			const validateAmount = (rule, value, callback) => {
				const num = /(^[0-9]\d*(\.\d{1,100})?$)|(^0(\.\d{1,100})?$)/;
				if (!num.test(value)) {
					callback(new Error(this.translateTitle("请输入正确格式")))
				} else {
					callback()
				}
			}
			return {
				value1: false,
				value: "",
				total: 0,
				type: 1,
				page: 1,
				pageSize: 10,
				detailData: "",
				showAddress: false,
				showAddress1: false,
				visible: false,
				visible1: false,
				loading: false,
				loading1: false,
				tableData: [],
				kycData:[],
				tableLoading: false,
				startDate: "",
				endDate: "",
				options1: [],
				options2: ["M", "F", "O"],
				idType: ["possport"],
				options3: ["Mr", "Mrs", "Miss"],
				options: [],
				activeName: "first",
				ruleForm: {
					billingAddress: "",
					email: "33@qq.com",
					billingCity: "",
					billingCountry: "",
					billingZipCode: "",
					billingAddress: "",
					billingAddressSecond: "",
					deliveryAddress: "",
					deliveryAddressSecond: "",
					deliveryState:'',
					billingState:'',
					deliveryCity: "",
					deliveryCountry: "",
					deliveryZipCode: "",
					dateOfBirth: "",
					firstName: "",
					gender: "",
					// idNumber: "",
					// idType: "",
					lastName: "",
					middleName: "",
					nationality: "",
					phone: "",
					phoneCountryCode: "",
					title: "",
				},
				ruleForm1: {
					name: "",
					email: "",
				},
				contactQuery: {
					total: 0,
					page: 0,
					pageSize: 10,
				},
				coinNameData: [],
				BalanceCoinNam: "",
				rules: {
					name: [{
						required: true,
						message: this.translateTitle("请输入姓名"),
						trigger: "change",
					}, ],
					email: [{
							required: true,
							message: this.translateTitle("请输入邮箱"),
							trigger: "change",
						},
						{
							required: true,
							validator: validateEmail,
							trigger: "change"
						}
					],
					phone: [{
							required: true,
							message: this.translateTitle("请输入电话号码"),
							trigger: "change",
						},
						{
							required: true,
							validator: validatePhone,
							trigger: "change"
						}
					],
					title: [{
						required: true,
						message: this.translateTitle("请选择标题"),
						trigger: "change",
					}, ],
					phoneCountryCode: [{
						required: true,
						message: this.translateTitle("请输入移动国家代码"),
						trigger: "change",
					}, ],
					lastName: [{
							required: true,
							message: this.translateTitle("请输入名字"),
							trigger: "change",
						},
						{
							required: true,
							validator: validateName,
							trigger: "change"
						}
					],
					firstName: [{
							required: true,
							message: this.translateTitle("请输入姓氏"),
							trigger: "change",
						},
						{
							required: true,
							validator: validateName,
							trigger: "change"
						}
					],
					middleName: [{
						required: false,
						validator: validateName,
						trigger: "change"
					}],
					nationality: [{
						required: true,
						message: this.translateTitle("请输入国籍"),
						trigger: "change",
					}, ],
					gender: [{
						required: true,
						message: this.translateTitle("请选择性别"),
						trigger: "change",
					}, ],
				
					dateOfBirth: [{
						required: true,
						message: this.translateTitle("请选择出生日期"),
						trigger: "change",
					}, ],

					type: [{
						type: "array",
						required: true,
						message: "请至少选择一个活动性质",
						trigger: "change",
					}, ],
					billingAddress: [{
							required: true,
							message: this.translateTitle("请输入地址"),
							trigger: "change",
						},
						{
							required: true,
							validator: validateAddress,
							trigger: "change"
						}
					],
					billingCountry: [{
						required: true,
						message: this.translateTitle("请选择国家"),
						trigger: "change",
					}, ],
					billingZipCode: [{
							required: true,
							message: this.translateTitle("请输入邮政编码"),
							trigger: "change",
						},
						{
							required: true,
							validator: validateCode,
							trigger: "change"
						}
					],
					deliveryAddress: [{
							required: true,
							message: this.translateTitle("请输入地址"),
							trigger: "change",
						},
						{
							required: true,
							validator: validateAddress,
							trigger: "change"
						}
					],
					deliveryAddressSecond: [{
							required: true,
							message: this.translateTitle("请输入地址"),
							trigger: "change",
						},
						{
							required: true,
							validator: validateAddress,
							trigger: "change"
						}
					],
					billingAddress: [{
							required: true,
							message: this.translateTitle("请输入地址"),
							trigger: "change",
						},
						{
							required: true,
							validator: validateAddress,
							trigger: "change"
						}
					],
					billingAddressSecond: [{
							required: true,
							message: this.translateTitle("请输入地址"),
							trigger: "change",
						},
						{
							required: true,
							validator: validateAddress,
							trigger: "change"
						}
					],
					deliveryDoorNo: [{
							required: true,
							message: this.translateTitle("请输入门牌"),
							trigger: "change",
						},
						{
							required: true,
							validator: validateAddress,
							trigger: "change"
						}
					],
					deliveryCountry: [{
						required: true,
						message: this.translateTitle("请选择国家"),
						trigger: "change",
					}, ],
					deliveryZipCode: [{
							required: true,
							message: this.translateTitle("请输入邮政编码"),
							trigger: "change",
						},
						{
							required: true,
							validator: validateCode,
							trigger: "change"
						}
					],
				},

				isCheck: "1",
				ruleForm2: {
					email: "",
					amount: "",
					coinName: "",
					coinLink: "",
				},
				choiceCoin: {
					coinName: '',
					coinLogo: "",
				},
				rules1: {
					amount: [{
							required: true,
							message: this.translateTitle("请输入数量"),
							trigger: "change",
						},
						{
							required: true,
							validator: validateAmount,
							trigger: "change"
						}
					],
					email: [{
						required: true,
						message: this.translateTitle("请输入邮箱"),
						trigger: "change",
					}, ],
				},
				coinNameData: [],
				CoinLinkOption: [],
				btnloading: false,
				billingCountryLogo: '',
				deliveryCountryLogo: '',
				nationalityLogo: '',
				phoneCountryLogo: '',
				transferRate: '',
				transferAmount: '',
				globalLoading: null,
			};
		},
		computed: {},
		watch: {},
		created() {
			if (this.$route.query.type == '2') {
				this.type = 2;
			} else {
				this.type = 1;
			}
			this.getUserinfoData();
			this.getCoinName(1);
			// this.ruleForm.phone = this.userInfo.phone;
			// this.ruleForm.email = this.userInfo.email;
		},
		methods: {
			multiply,
			subtr,
			translateTitle,
			async getCoinLink(val) {
				let res = await coinLink(val);
				this.CoinLinkOption = res.data;
			},
			changeAmount(params) {
				if (params == '') {
					this.transferAmount = '';
				} else {
					this.getTransferAmount(params)
				}
			},
			changeCoinLink() {
				this.getBalanceCoin(2);
			},
			async getUserinfoData(val) {
				let res = await getUserinfo();
				if (val == "1") {
					this.ruleForm = res.data;
				} else {
					this.userInfo = res.data;
					this.ruleForm = res.data;
				}
				let res1 = await getIdTypes()
				this.options1 = res1.data
				this.getNationalData();
			},
			getBalanceCoin(type) {
				if (type == "1") {
					getBalanceCoinNam(this.ruleForm2.coinName).then((res) => {

						if (res.code == 200) {
							this.BalanceCoinNam = res.data.amount;
						} else {
							this.$message.error(this.translateTitle(res.msg));
						}
					});
				} else {
					if (this.ruleForm2.coinName && this.ruleForm2.coinLink) {
						let params = {
							coinName: this.ruleForm2.coinName,
							coinLink: this.ruleForm2.coinLink,
						};
						coinBalanceUSD(params).then((res) => {
							if (res.code == 200) {
								this.BalanceCoinNam = res.data.balance;
							} else {
								this.$message.error(this.translateTitle(res.msg));
							}
						});
					}
				}
			},
			async getCoinName(val) {
				let res = await coinNames();
				this.coinNameData = res.data;
				this.choiceCoin.coinName = this.coinNameData[0].coinName;
				this.choiceCoin.coinLogo = this.coinNameData[0].coinLogo;
				this.ruleForm2.coinName = this.coinNameData[0].coinName;
				this.ruleForm2.coinLink = this.coinNameData[0].coinLink;
				this.coin
				if (val == "2") {
					this.getCoinLink(this.ruleForm2.coinName);
				}
				// this.getBalanceCoin(val);
			},
			getTransferAmount(val) {
				let params = {
					coinLink: "USD",
					coinName: "USD",
					inputAmount: val || 0,
					type: '0'
				}
				calculateCharge(params).then(res => {
					this.transferAmount = res.data.charge
				})
			},
			async getKyc(){
				let res = await userKycDetails();
				this.kycData = [];
				if (res.code == 200) {
					let kycObj = res.data;
					this.kycData.push(kycObj);
				} else {
					this.$message.error(res.msg);
				}
			},
			async getContact() {
				const data = {
					pageSize: this.contactQuery.pageSize,
					page: this.contactQuery.page,
				};
				const loading = this.$loading({
					lock: true,
					text: '',
					spinner: '',
					background: 'rgba(255, 255, 255, 0.8)'
				});
				let res = await contactPage(data);
				this.tableData = res.data.records;
				this.contactQuery.total = res.data.total;
				loading.close()
			},
			async getNationalData() {
				let res = await getNational();
				this.options = res.data;
				this.changebillingCountry(this.ruleForm.billingCountry)
				this.changenationality(this.ruleForm.nationality)
				this.changedeliveryCountry(this.ruleForm.deliveryCountry)
				this.changePhoneCountry(this.ruleForm.phoneCountryCode)
			},
			handleClick(tab, event) {
				this.page = 1;
				if (this.type == "1") {
					if (this.activeName == "first") {
						this.getUserinfoData();
					} else if (this.activeName == 'third') {
						this.getContact();
						this.checkType()
					}else if(this.activeName =='zero'){
						this.getKyc();
					}
				}
			},
			descclick(val) {
				this.type = 2;
				this.activeName = "first";
				this.getUserinfoData(1);
			},
			changeType() {
				this.type = 2;
				this.activeName = "first";
				this.getNationalData();
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						// this.loading1 = true
						this.globalLoading = this.$loading({
							lock: true,
							text: '',
							spinner: '',
							background: 'rgba(255, 255, 255, 0.8)'
						});
						addUserInfo(this.ruleForm).then((res) => {
							if (res.code == 200) {
								this.$message.success(this.translateTitle("操作成功"));
								this.getUserinfoData()
							} else {
								this.$message.error(this.translateTitle(res.msg));
							}
						}).finally(() => {
							// this.loading1 = false
							this.globalLoading.close()
						});
						// alert("submit!");
					} else {
						return false;
					}
				});
			},
			onSure(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						// this.btnloading = true
						this.globalLoading = this.$loading({
							lock: true,
							text: '',
							spinner: '',
							background: 'rgba(255, 255, 255, 0.8)'
						});
						addContact(this.ruleForm1).then((res) => {
							if (res.code == 200) {
								this.$message.success(this.translateTitle("操作成功"));
								this.getContact();
								this.visible = false;
								this.$refs["ruleForm1"].resetFields();
							} else {
								this.$message.error(this.translateTitle(res.msg));
							}
						}).finally(() => {
							// this.btnloading = false
							this.globalLoading.close()
						});
					} else {
						return false;
					}
				});
			},
			//删除联系人
			deleteContact(row) {
				this.$confirm(
						this.translateTitle("删除后不可恢复"), {
							confirmButtonText: this.translateTitle("确定"),
							cancelButtonText: this.translateTitle("取消"),
							customClass: 'custom-message',
						}
					)
					.then(async () => {
						this.globalLoading = this.$loading({
							lock: true,
							text: '',
							spinner: '',
							background: 'rgba(255, 255, 255, 0.8)'
						});
						const res = await deleteDontact(row.id);
						if (res.code == 200) {
							this.$message.success(this.translateTitle("操作成功"));
							this.getContact();
							this.globalLoading.close()
						} else {
							this.$message.error(this.translateTitle(res.msg));
						}
					})
					.catch(() => {});
			},
			editAddress(val) {
				if (val == "0") {
					this.showAddress = true;
				} else {
					this.showAddress1 = true;
				}
			},
			//切换联系人列表
			handleperson() {
				this.type = 3;
				this.showAddress = false;
				this.showAddress1 = false;
				this.activeName = "first";
				this.getContact();
			},
			addPerson() {
				this.visible = true;
			},
			//关闭添加联系人
			onClose() {
				//   this.$refs[formName].resetFields();
				this.visible = false;
			},
			bankTransfer(row) {
				this.visible1 = true;
				if (row) {
					this.ruleForm2.email = row.email;
				} else {
					this.isCheck = 2;
				}
			},
			initiateKYC(row){
				if(row.showIframe){
					window.open(row.kycLink, '_blank');
				}
			},
			handcreateMccard() {
				this.globalLoading = this.$loading({
					lock: true,
					text: '',
					spinner: '',
					background: 'rgba(255, 255, 255, 0.8)'
				});
				createMccard().then((res) => {
					if (res.code == 200) {
						this.$message.success(this.translateTitle("操作成功"));
					} else {
						this.$message.error(this.translateTitle(res.msg));
					}
				}).finally(() => {
					this.globalLoading.close()
				})
			},
			changeCoinName(data) {
				let obj = this.coinNameData.find(item => item.coinName === data)
				this.choiceCoin.coinName = obj.coinName;
				this.choiceCoin.coinLogo = obj.coinLogo;
				this.ruleForm2.coinName = obj.coinName;
				this.ruleForm2.coinLink = "";
				this.getCoinLink(this.ruleForm2.coinName);
				this.getBalanceCoin(2);
			},
			checkType() {
				userBankBalance().then((res) => {
					this.BalanceCoinNam = res.data.amount;
				});
			},
			onSure1(formName) {
				if (this.isCheck == "") {
					return this.$message.error(this.translateTitle("请先选择转账方式"));
				} else {
					let _resquest;
					let params;
					if (this.isCheck == "1") {
						_resquest = bank_transfer;
						params = {
							amount: this.ruleForm2.amount,
							email: this.ruleForm2.email,
						};
					} else {
						_resquest = coin_transfer;
						params = {
							amount: this.ruleForm2.amount,
							email: this.ruleForm2.email,
							coinName: this.ruleForm2.coinName,
							coinLink: this.ruleForm.coinLink,
						};
					}
					this.$refs[formName].validate((valid) => {
						if (valid) {
							// this.btnloading = true
							this.globalLoading = this.$loading({
								lock: true,
								text: '',
								spinner: '',
								background: 'rgba(255, 255, 255, 0.8)'
							});
							_resquest(params).then((res) => {
								if (res.code == 200) {
									this.$message.success(this.translateTitle("操作成功"));
									// this.getContact()
									this.visible1 = false;
									this.$refs["ruleForm2"].resetFields();
								} else {
									this.$message.error(this.translateTitle(res.msg));
								}
							}).finally(() => {
								// this.btnloading = false
								this.globalLoading.close()
							});
						} else {
							return false;
						}
					});
				}
			},
			onClose1() {
				this.$refs["ruleForm2"].resetFields();
				this.visible1 = false;
			},
			onCopy(text) {
				if (navigator.clipboard) {
					// clipboard api 复制
					navigator.clipboard.writeText(text);
				} else {
					const textarea = document.createElement("textarea");
					document.body.appendChild(textarea);
					// 隐藏此输入框
					textarea.style.position = "fixed";
					textarea.style.clip = "rect(0 0 0 0)";
					textarea.style.top = "10px";
					// 赋值
					textarea.value = text;
					// 选中
					textarea.select();
					// 复制
					document.execCommand("copy", true);
					// 移除输入框
					document.body.removeChild(textarea);
				}
				this.$message.success(this.translateTitle("内容已复制到剪贴板"));
			},
			copyBilling() {
				this.ruleForm.billingCity = this.ruleForm.deliveryCity;
				this.ruleForm.billingAddress = this.ruleForm.deliveryAddress;
				this.ruleForm.billingCountry = this.ruleForm.deliveryCountry;
				this.ruleForm.billingAddressSecond = this.ruleForm.deliveryAddressSecond;
				this.ruleForm.billingZipCode = this.ruleForm.deliveryZipCode;
				this.ruleForm.billingState = this.ruleForm.deliveryState;
			},
			changebillingCountry(data) {
				let obj = this.options.find(item => item.alpha2 === data)
				this.billingCountryLogo = obj.logo
			},
			changenationality(data) {
				let obj = this.options.find(item => item.alpha2 === data)
				this.nationalityLogo = obj.logo
			},
			changedeliveryCountry(data) {
				let obj = this.options.find(item => item.alpha2 === data)
				this.deliveryCountryLogo = obj.logo
			},
			changePhoneCountry(data) {
				let obj = this.options.find(item => item.phoneCode === data)
				this.phoneCountryLogo = obj.logo
			}
		},
	};
</script>

<style lang="less" scoped>
	.setting-detail {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 22px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: bold;
		color: #31363e;
		margin-top: 30px;
		margin-bottom: 30px;

		.el-tag {
			margin-left: 15px;
		}
	}

	.table-detali {
		margin-top: 15px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.el-date-editor {
			margin-left: 10px;
			width: 300px;
		}

		img {
			width: 40px;
			vertical-align: middle;
		}
	}

	.succ {
		color: #3ad3b9;
	}

	.err {
		color: #d33a3a;
	}

	::v-deep {
		//   .el-form-item--feedback .el-input__validateIcon {
		//     /* display: inline-block; */
		// }

		.el-table {
			img {
				vertical-align: middle;
				margin-left: 20px;
			}
		}

		.el-tabs__nav-wrap::after {
			background-color: transparent;
			height: 0;
		}

		.el-tabs__active-bar {
			background: #786234;
			height: 3px;
		}

		.el-tabs__item {
			font-size: 16px;
			font-family: Microsoft YaHei-Regular, Microsoft YaHei;
			font-weight: 400;
			color: #31363E;
		}

		.el-tabs__item.is-active {
			font-size: 18px;
			font-family: Microsoft YaHei-Regular, Microsoft YaHei;
			font-weight: bold;
			color: #353a42;
		}
	}

	.el-col-17 {
		padding: 20px;
	}

	.el-col-7 {
		background: #f8fafb;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		padding: 20px;

		.line1 {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.left {
				width: 30px;
			}

			.right {
				display: flex;
				align-items: center;

				.text {
					text-align: right;
					font-family: Microsoft YaHei-Regular, Microsoft YaHei;
					font-weight: bold;
					font-size: 14px;

					.name1 {
						color: #31363e;
						margin-bottom: 5px;
					}

					.name2 {
						color: #b9bfca;
					}
				}

				.el-dropdown {
					display: flex;
					align-items: center;

					img {}
				}

				.img1 {
					width: 50px;
					margin: 0 5px 0 5px;
				}

				.img2 {
					width: 20px;
					height: 20px;
				}
			}
		}

		.line2 {
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-weight: bold;
			margin: 35px 0 20px 0px;
			font-family: Microsoft YaHei-Regular, Microsoft YaHei;

			.left {
				font-size: 22px;
				color: #31363e;
			}

			.right {
				font-size: 18px;
				color: #353a42;

				img {
					width: 20px;
					vertical-align: middle;
				}
			}
		}

		.line3 {
			font-size: 17px;
			margin: 20px 0px;

			.left {
				color: #b9bfca;
			}

			.right {
				font-family: Microsoft YaHei-Regular, Microsoft YaHei;
				font-weight: 800;
				color: #31363e;
			}
		}

		.btn {
			width: 85%;
			height: 60px;
			background: #353a42;
			border-radius: 10px;
			color: #fff;
			text-align: center;
			line-height: 60px;
			font-size: 19px;
			margin: 20px auto;
		}

		.line4 {
			img {
				width: 60%;
				border-radius: 20px;
			}

			margin: 50px auto;
			text-align: center;
		}

		::v-deep {
			.el-select .el-input {
				width: 100px;
			}

			.input-with-select .el-input-group__append {
				background-color: #fff;
			}

			.el-input__inner,
			.el-input-group__append,
			.el-input-group__prepend {
				border-color: transparent;
				height: 45px;
			}
		}
	}

	.person-form {
		font-size: 16px;
		font-weight: 400;
		color: #31363E;
		line-height: 26px;

		.edress-content {
			margin-left: 0px !important;
			margin-right: 0px !important;
			// padding: 40px;
			// background: #E7EEF7;
			border-radius: 10px;
			margin-top: 10px;
			margin-bottom: 10px;
			font-size: 20px;
			color: rgba(121, 127, 136, 0.4);
			line-height: 26px;

			.title {
				font-size: 16px !important;
				font-weight: bold;
				color: #31363E;
				line-height: 26px;
				padding-left: 25px;
			}

			.title1 {
				font-size: 16px;
				color: #31363E;
			}

			.editbtn {
				// width: 200px;
				// height: 40px;
				// text-align: center;
				// color: #fff;
				// line-height: 40px;
				// background: #cfe5ee;
				// border-radius: 10px;
				// margin: 20px;
			}

			::v-deep {
				.el-form-item__label {
					font-size: 14px;
					font-weight: 400;
					color: rgba(49, 54, 62, 0.6);
				}
			}
		}

		.edress-content1 {
			display: flex;
			// justify-content: space-between;
			// margin-bottom: 15px;
		}

		.edress-content2 {
			font-size: 20px;
			color: #3D3D3D;
			line-height: 26px;
		}

		.el-input__inner {
			height: 45px;
			box-shadow: 3px 16px 13px 0px rgba(233, 238, 240, 0.28);
			border-radius: 17px;
			border: none;
		}

		.el-date-editor.el-input,
		.el-date-editor.el-input__inner,
		.el-select {
			width: 100%;
		}

		.btn {
			width: 262px;
			height: 45px;
			background: #353a42;
			border-radius: 10px;
			color: #fff;
			text-align: center;
			line-height: 45px;
			font-size: 14px;
		}
	}

	.change-form {

		.el-col-12,
		.el-col-8 {
			margin-bottom: 10px;
		}

		.title {
			margin-bottom: 15px !important;
		}
	}

	::v-deep {
		.primary-plain.el-button--primary.is-plain {
			border: 1px solid #786234 !important;
			color: #786234 !important;
		}

		.custum-dialog {
			display: flex;
			justify-content: space-between;
			margin-bottom: 20px;

			.img {
				text-align: center;
				background: #f2f7f9;
				border-radius: 10px;
				// padding: 30px 30px 20px 30px;
				padding: 20px 0;
				border: 2px solid #f2f7f9;
				width: 46%;

				p {
					font-size: 22px;
					font-family: Microsoft YaHei-Regular, Microsoft YaHei;
					font-weight: bold;
					color: #24282e;
				}

				// img {
				//   width: 60%;
				// }
			}

			.img.active {
				border: 2px solid #f6d655;
			}
		}
	}

	.select-item {
		margin-left: 10px;
	}
</style>
<style>
	.dropdownPop img {
		width: 25px;
		vertical-align: middle;
		margin-right: 5px;
	}

	.el-form-item--feedback .el-input__validateIcon {
		display: none !important;
	}
</style>